import React from "react";

export default function MultiplayerHeader() {
  return (
    <div className="header">
      {/* {sessionStorage.user1 && (
        <div style={styles1}>
          <h5>{sessionStorage.user1}</h5>
          <div>
            Score:<div></div>
          </div>
        </div>
      )}
      {sessionStorage.user2 && (
        <div style={styles2}>
          <h5>{sessionStorage.user2}</h5>
          <div>
            Score:<div></div>
          </div>
        </div>
      )} */}
    </div>
  );
}
