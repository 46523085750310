import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Difficulties from "../constants/Difficulties";
import Timer from "../containers/Timer";
import { init } from "../scripts/init";
import SoloHeader from "./SoloHeader";
import MemoryGameFooterList from "./Footer";
import axios from "axios";
import { LibIdsContext } from "./App";

class SinglePlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flippedIndexes: [],
      flippedKeys: [],
      correctFlipped: [],
      wrongOpened: [],
      items: [],
      seconds: 20,
      delay: 5,
      won: false,
      loading: true,
      score: 0,
      movies: 0,
      startGame: false,
    };

    this.setStateAsync = this.setStateAsync.bind(this);
  }

  async componentDidMount() {
    let tr1 = document.getElementById("tr1").value;
    let tr2 = document.getElementById("tr2").value;
    // console.log(time,'sdfsdfsewf');
    let value = this.context;
  

    const words = [];
    const newData = JSON.parse(sessionStorage.singleGameWords)
    // await axios.get(
    //   `https://2321.freelancedeveloper.site/services/VocabulariesService.php?url=setroom&id=[1,2,3]&roomid=0`
    //   );
      // const newData = JSON.parse(getData.data[getData.data.length-1]);
      let usedIds = [];
      await newData.map((item) => {
        if (tr2 === "eng") {
          words.push({ id: item[0], name: item[0] });
        } else {
          let alreadyused = false;
          for (let i = 0; i < usedIds.length; i++) {
            if (item[0] == usedIds[i]) {
              alreadyused = true;
              words.push({ id: item[0], name: item[1] });
              break;
            }
          }
          if (!alreadyused) {
            usedIds.push(item[0]);
            words.push({ id: item[0], name: item[0] });
          }
          // words.push({ id: item[0], name: item[1] });
        }
      });
      
      const { seconds } = init(this.props.gameRoom.difficulty);
      await this.setStateAsync({
        items: words,
        seconds,
        loading: false,
      });
      
      console.log(getData,'ghdfuyhsasf');
    /* keep images open for some time to memorize */
    this.state.items.map((source, key) =>
      this.setState({ flippedIndexes: [...this.state.flippedIndexes, key] })
    );
    setTimeout(() => {
      this.setState({ flippedIndexes: [] });
      this.setState({ startGame: true });
    }, this.state.delay * 1000);

    /* show modal when time is ended and make game over */
    let timeEnded = setInterval(() => {
      if (this.props.timeEnded) {
        clearInterval(timeEnded);
        setTimeout(this.props.endGame, 5000);
      }
    }, 1000);
  }

  /* make setState work with async/await */
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async flipItem(index, key) {
    if (
      this.state.flippedKeys.length < 2 &&
      index !==
        this.state.flippedIndexes[this.state.flippedIndexes.length - 1] &&
      this.state.flippedIndexes.length !== this.state.items.length
    ) {
      await this.setStateAsync({
        flippedIndexes: [...this.state.flippedIndexes, index],
        flippedKeys: [...this.state.flippedKeys, key],
      });
      if (this.state.flippedKeys.length === 2) {
        let difficultiOpt = document.getElementsByClassName("choosen");
        if (difficultiOpt) {
          let time =
            difficultiOpt[0].innerText === "Fast"
              ? 500
              : difficultiOpt[0].innerText === "Standard"
              ? 1000
              : 2000;

          setTimeout(async () => {
            if (this.state.flippedKeys[0] === this.state.flippedKeys[1]) {
              let updatedPoints = this.state.score + 1;
              this.setState({
                ...this.state,
                score: updatedPoints,
                correctFlipped: [
                  ...this.state.correctFlipped,
                  ...this.state.flippedKeys,
                ],
              });
              if (
                this.state.flippedIndexes.length === this.state.items.length
              ) {
                this.setState({ won: true });
                setTimeout(this.props.endGame, 5000);
              }
            } else {
              let flippedIndexes = this.state.flippedIndexes;
              flippedIndexes.splice(-2, 2);
              let updateMovie = this.state.movies + 1;
              await this.setStateAsync({
                ...this.state,
                movies: updateMovie,
              });
              await this.setStateAsync({ flippedIndexes: flippedIndexes });
            }
            this.setState({ flippedKeys: [] });
          }, time);
        }
      }
    }
  }

  setColors(source, i) {
    const styles = {};
    if (this.state.correctFlipped.length % 2 === 0) {
      if (
        this.state.correctFlipped.filter((item) => item === source.id)
          .length === 2
      ) {
        styles.background = "green";
      }
    } else {
      styles.background = "white";
      styles.color = "black";
    }
    return styles;
  }

  render() {
    // console.log(this.state.flippedKeys);
    console.log(this.state);
    return (
      <div className="game-room">
        {this.state.loading ? (
          <div>Loading...</div>
        ) : (
          <div style={{ maxWidth: 930, background: "#f1f4f6" }}>
            <SoloHeader
              seconds={this.state.seconds}
              delay={this.state.delay}
              stop={this.state.won}
              score={this.state.score}
              movies={this.state.movies}
              startGame={this.state.startGame}
            />
            <div
              className={
                "arena " + Difficulties[this.props.gameRoom.difficulty]
              }
            >
              {this.state.items.map((source, i) => {
                return (
                  <div
                    key={i}
                    style={{ ...this.setColors(source, i) }}
                    // style={{background:this.state.flippedKeys.filter(item=>item === source.name).length>1?'green':'red'}}
                    className={
                      ~this.state.flippedIndexes.indexOf(i)
                        ? "item matched"
                        : "item flipped flipped-background"
                    }
                    onClick={() => this.flipItem(i, source.id)}
                  >
                    <div className="front face">
                      <h4 style={{lineBreak:'anywhere'}}>{source.name}</h4>
                    </div>
                    <div className="back face" />
                  </div>
                );
              })}
            </div>
            <div className="sign-out" onClick={this.props.endGame}>
              <i className="fas fa-sign-out-alt"></i>
            </div>
            <Modal isOpen={this.props.timeEnded} centered>
              <ModalHeader>Game over</ModalHeader>
              <ModalBody>
                Your time is over. You will be redirected soon.
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.won} centered>
              <ModalHeader>You Won!</ModalHeader>
              <ModalBody>
                You won this round. You will be redirected soon.
              </ModalBody>
            </Modal>
          </div>
        )}
        <MemoryGameFooterList />
      </div>
    );
  }
}

SinglePlayer.propTypes = {
  gameRoom: PropTypes.object.isRequired,
  timeEnded: PropTypes.bool.isRequired,
  endGame: PropTypes.func.isRequired,
  increaseTime: PropTypes.func.isRequired,
};
SinglePlayer.contextType = LibIdsContext;
export default SinglePlayer;
