import React, { useEffect, useState } from "react";
// import compare from "../../assets/img/compare.svg";

function MemoryGameFooterList({ changeMode, mode }) {
  const [playVariant, setPlayVariant] = useState(0);
  const [chooseVoc, setChooseVoc] = useState("eng");
  const [activeSpeed, setActiveSpeed] = useState("standard");
  const [changeSpeed, setChangeSpeed] = useState("Standard");

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = "Language changed to " + chooseVoc;
    });
  }, [chooseVoc]);

  let changeLangs = (ev) => {
    setChooseVoc(ev);
    sessionStorage.settedLang = ev;
    window.location.reload();
  };
  return (
    <div className="footer">
      <input value={changeSpeed} hidden id="difficulty" />
      <li className="footerList">
        <div className="footerListTitle">Game mode:</div>
        <div className="footerListCards">
          <div
            className={`${!mode ? "choosen" : "card"}`}
            onClick={mode ? changeMode : null}
          >
            Solo
          </div>
          <div
            className={`${mode ? "choosen" : "card"}`}
            onClick={!mode ? changeMode : null}
          >
            With a friend
          </div>
          <div className="card">Against computer</div>
        </div>
      </li>
      <li className="footerList">
        <div className="footerListTitle">Speed:</div>
        <div className="footerListCards">
          <div
            className={changeSpeed === "Fast" ? "choosen" : "card"}
            onClick={() => setChangeSpeed("Fast")}
          >
            Fast
          </div>
          <div
            className={changeSpeed === "Standard" ? "choosen" : "card"}
            onClick={() => setChangeSpeed("Standard")}
          >
            Standard
          </div>
          <div
            className={changeSpeed === "Slow" ? "choosen" : "card"}
            onClick={() => setChangeSpeed("Slow")}
          >
            Slow
          </div>
        </div>
      </li>
      <li className="footerList">
        <div className="footerListTitle">Vocabulary list:</div>
        <div
          className="footerListCards"
          onClick={() =>
            (window.location.href =
              "https://2321.freelancedeveloper.site/addNewList.php")
          }
        >
          {/* <div className={styles.card}>Vocabulary List 1</div> */}
          <div className="selected">Create vocabulary list</div>
        </div>
      </li>
      <li className="footerList">
        <div className="footerListTitle">Language:</div>
        <div className="footerListCards">
          <select
            name=""
            id="tr1"
            className="selected"
            defaultValue={"eng"}
            onChange={(e) => changeLangs(e.target.value)}
          >
            <option value="eng" defaultValue>
              English
            </option>
            <option value="ger">German</option>
          </select>
          <div>{/* <img src={compare} alt="compare" /> */}</div>
          <select
            name=""
            id="tr2"
            className="card"
            defaultValue={sessionStorage.settedLang || chooseVoc}
            onChange={(e) => changeLangs(e.target.value)}
          >
            <option value="ger">German</option>
            <option value="eng">English</option>
          </select>
        </div>
      </li>
    </div>
  );
}

export default MemoryGameFooterList;
