import React, { useEffect, useState, createContext } from "react";
import PropTypes from "prop-types";
import Rooms from "../containers/Rooms";
import Game from "../containers/Game";
import styles from "../stylesheets/main.scss";

export const LibIdsContext = createContext();
function App(props) {
  const [libs, setLibs] = useState(null);
  useEffect(() => {
    let ids = window.location.search.split("=")[1];
    setLibs(ids);
  }, []);
 
  return (
    <LibIdsContext.Provider value={libs}>
      {/* {libs&& */}
      <div className="app">
        {props.gameStarted ? <Game/> : <Rooms/>}
      </div>
      {/* } */}
    </LibIdsContext.Provider>
  );
}

export default App;
