import React, { Component } from "react";
import SinglePlayer from "./SinglePlayer";
import MultiPlayer from "./MultiPlayer";

function Game(props) {

  return (
    <div className="app-game">
      {props.isMultiPlayer ? (
        <MultiPlayer {...props} />
      ) : (
        <SinglePlayer {...props} />
      )}
    </div>
  );
}

export default Game;
