import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./containers/App";
import { BrowserRouter, Route, Routes } from "react-router-dom";

render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
