import React, { Component } from "react";
import PropTypes from "prop-types";
import Difficulties from "../constants/Difficulties";
import {
  requestMembers,
  getMembers,
  requestRoomStatuses,
  getRoomsStatuses,
} from "../socket";
import axios from "axios";
import MemoryGameFooterList from "./Footer";

class Rooms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameType: false,
      members: new Array(12).fill(0),
      roomStatuses: new Array(12).fill(true),
      singleWords: [],
    };

    this.toggleGameType = this.toggleGameType.bind(this);
    this.startGame = this.startGame.bind(this);
  }

  componentWillMount() {
    this.props.fetchRooms();
  }

  componentDidMount() {
    requestMembers();
    getMembers((data) => this.setState({ members: data }));

    requestRoomStatuses();
    getRoomsStatuses((roomStatuses) => {
      this.setState({ roomStatuses: roomStatuses });
    });
  }

  toggleGameType() {
    console.log(this.state.gameType);
    this.setState({ gameType: !this.state.gameType });
  }

  async startGame(room, gamers, type) {
    console.log(gamers);
    if (!this.state.gameType) {
      let ids = window.location.search.split("=")[1];
      console.log("one", "one");
      sessionStorage.roomId = room.id;
      const singleData = await axios.get(
        `https://2321.freelancedeveloper.site/services/VocabulariesService.php?url=setroom&id=${ids}&roomid=0`
      );
      sessionStorage.singleGameWords = JSON.stringify(
        singleData.data[singleData.data.length - 1]
      );
      // await this.setState({...this.state,singleWords:singleData.data[singleData.data.length-1]})
    } else if (this.state.gameType && gamers === 0) {
      let ids = window.location.search.split("=")[1];
      sessionStorage.roomId = room.id;
      console.log("one");
      axios.get(
        `https://2321.freelancedeveloper.site/services/VocabulariesService.php?url=setroom&id=${ids}&roomid=${room.id}`
      );
    } else if (this.state.gameType && gamers === 1) {
      sessionStorage.roomId = room.id;
      console.log("two");
    } else {
      sessionStorage.roomId = room.id;
    }
    if (
      !this.state.gameType ||
      (room.members != this.state.members[room.id].length &&
        this.state.roomStatuses[room.id])
    ) {
      this.props.startGame(room, this.state.gameType);
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="rooms-area">
          <div className="game-types">
            <div
              className={
                !this.state.gameType ? "game-type active" : "game-type"
              }
              onClick={this.state.gameType ? this.toggleGameType : null}
            >
              SOLO
            </div>
            <div
              className={this.state.gameType ? "game-type active" : "game-type"}
              onClick={!this.state.gameType ? this.toggleGameType : null}
            >
              PLAY WITH FRIEND
            </div>
          </div>
          <div className="rooms">
            <table
              className={
                !this.state.gameType
                  ? "table singular-rooms active"
                  : "table singular-rooms"
              }
            >
              <tbody>
                {this.props.rooms &&
                  this.props.rooms.slice(0, 1).map((room, key) => (
                    <tr key={key} className="room">
                      <td className="name">Play</td>
                      <td
                        className="options"
                        onClick={() =>
                          this.startGame(
                            room,
                            this.state.members[room.id].length,
                            "one"
                          )
                        }
                      >
                        <i className="fas fa-sign-in-alt"></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <table
              className={
                this.state.gameType
                  ? "table multiple-rooms active"
                  : "table multiple-rooms"
              }
            >
              <thead>
                <tr>
                  <th>Room</th>
                  <th>Members</th>
                  {/* <th>Difficulty</th> */}
                  <th>Join</th>
                </tr>
              </thead>
              <tbody>
                {this.props.rooms &&
                  this.props.rooms.map((room, key) => (
                    <tr key={key} className="room">
                      <td className="name">{room.name}</td>
                      <td className="members">
                        {[...new Array(room.members).keys()].map(
                          (member, id) => (
                            <b
                              key={id}
                              className={
                                this.state.members[room.id]&&this.state.members[room.id].length > id
                                  ? "fa fa-user"
                                  : "far fa-user"
                              }
                            ></b>
                          )
                        )}
                      </td>
                      <td
                        className="options"
                        onClick={() =>
                          this.startGame(
                            room,
                            this.state.members[room.id].length,
                            "multi"
                          )
                        }
                      >
                        <i
                          className={
                            this.state.roomStatuses[room.id]
                              ? "fas fa-sign-in-alt"
                              : "far fa-times-circle"
                          }
                        ></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <MemoryGameFooterList
          changeMode={this.toggleGameType}
          mode={this.state.gameType}
        />
      </div>
    );
  }
}

Rooms.propTypes = {
  fetchRooms: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  startGame: PropTypes.func.isRequired,
  endGame: PropTypes.func.isRequired,
};

export default Rooms;
