import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import Modal from "../components/Modal";
import Difficulties from "../constants/Difficulties";
import { init } from "../scripts/init";
import {
  addMember,
  getMembers,
  removeMember,
  setItems,
  getItems,
  setFlipped,
  getFlipped,
  isRemoved,
  setRoomStatuses,
} from "../socket";
import axios from "axios";
import MemoryGameFooterList from "./Footer";
import MultiplayerHeader from "./MultiplayerHeader";

class MultiPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flippedIndexes: [],
      flippedKeys: [],
      items: [],
      gameEnded: false,
      members: [],
      memberId: "",
      started: false,
      activeMemberId: "",
      points: {},
      winner: "",
      correctFlipped:[]
    };

    this.setStateAsync = this.setStateAsync.bind(this);
  }

  async componentDidMount() {
    const words = [];
    let tr1 = document.getElementById("tr1").value;
    let tr2 = document.getElementById("tr2").value;
    const getData = await axios.get(
      `https://2321.freelancedeveloper.site/services/VocabulariesService.php?url=getroom&roomid=${sessionStorage.roomId}`
    );
    const newData = JSON.parse(getData.data.voc);
    // await newData.map((item) => {
    //   if (tr2 === "eng") {
    //     words.push({ id: item[0], name: item[0] });
    //   } else {
    //     words.push({ id: item[0], name: item[1] });
    //   }
    // });

    let usedIds = [];
    await newData.map((item) => {
      if (tr2 === "eng") {
        words.push({ id: item[0], name: item[0] });
      } else {
        let alreadyused = false;
        for (let i = 0; i < usedIds.length; i++) {
          if (item[0] == usedIds[i]) {
            alreadyused = true;
            words.push({ id: item[0], name: item[1] });
            break;
          }
        }
        if (!alreadyused) {
          usedIds.push(item[0]);
          words.push({ id: item[0], name: item[0] });
        }
        // words.push({ id: item[0], name: item[1] });
      }
    });

    const { items } = init(this.props.gameRoom.difficulty);
    setItems(this.props.gameRoom.id, items);
    getItems((data) => {
      this.setStateAsync({ items: words });
    });

    getFlipped(async (data) => {
      if ((data.room = this.props.gameRoom.id)) {
        await this.setStateAsync({
          flippedIndexes: data.flippedIndexes,
          flippedKeys: data.flippedKeys,
        });

        if (this.state.flippedKeys.length === 2) {
          setTimeout(async () => {
            if (this.state.flippedKeys[0] === this.state.flippedKeys[1]) {
              let updatedPoints =
                this.state.points[this.state.activeMemberId] + 2;
              this.setState({
                points: {
                  ...this.state.points,
                  [this.state.activeMemberId]: updatedPoints,
                },
              });
              this.setState({...this.state,correctFlipped: [
                ...this.state.correctFlipped,
                ...this.state.flippedKeys,
              ],})
              if (
                this.state.flippedIndexes.length === this.state.items.length
              ) {
                const points = this.state.points;
                const winner = Object.keys(this.state.points).reduce((a, b) => {
                  return points[a] > points[b]
                    ? a
                    : points[a] < points[b]
                    ? b
                    : "";
                });
                this.setState({ gameEnded: true, winner: winner });
                setTimeout(() => {
                  setRoomStatuses(this.props.gameRoom.id, true);
                  this.props.endGame();
                }, 5000);
              }
            } else {
              let flippedIndexes = this.state.flippedIndexes;
              flippedIndexes.splice(-2, 2);
              await this.setStateAsync({ flippedIndexes: flippedIndexes });

              /* Change Active Member */
              let members = this.state.members[this.props.gameRoom.id];
              let nextActive = members.indexOf(this.state.activeMemberId) + 1;
              if (nextActive >= members.length) {
                nextActive = 0;
              }
              this.setState({ activeMemberId: members[nextActive] });
            }
            this.setState({ flippedKeys: [] });
          }, 1000);
        }
      }
    });

    addMember(this.props.gameRoom.id);
    getMembers((data) => {
      /* set active member id */
      if (this.state.activeMemberId) {
        let members = this.state.members[this.props.gameRoom.id];
        let nextActive = members.indexOf(this.state.activeMemberId);
        if (nextActive >= data[this.props.gameRoom.id].length) {
          nextActive = 0;
        }
        this.setState({
          activeMemberId: data[this.props.gameRoom.id][nextActive],
        });
      } else {
        this.setState({ activeMemberId: data[this.props.gameRoom.id][0] });
      }

      /* get all members */
      this.setState({ members: data });

      /* set initial points */
      for (let memberId of this.state.members[this.props.gameRoom.id]) {
        if (!this.state.points[memberId]) {
          this.setState({ points: { ...this.state.points, [memberId]: 0 } });
        }
      }

      /* set current member */
      if (!this.state.memberId) {
        this.setState({
          memberId:
            data[this.props.gameRoom.id][
              data[this.props.gameRoom.id].length - 1
            ],
        });
      }

      /* start if all members arrived */
      if (data[this.props.gameRoom.id].length === this.props.gameRoom.members) {
        this.setState({ started: true });
        setRoomStatuses(this.props.gameRoom.id, false);
      }
    });

    isRemoved((removed) => {
      if (
        removed &&
        this.state.members[this.props.gameRoom.id].length === 1 &&
        this.state.started
      ) {
        this.setState({
          gameEnded: true,
          winner: this.state.members[this.props.gameRoom.id][0],
        });
        setTimeout(() => {
          setRoomStatuses(this.props.gameRoom.id, true);
          this.props.endGame();
        }, 5000);
      }
    });

    window.addEventListener("beforeunload", () => {
      removeMember(this.props.gameRoom.id);
    });
  }

  componentWillUnmount() {
    removeMember(this.props.gameRoom.id);
    window.removeEventListener("beforeunload", () => {
      removeMember(this.props.gameRoom.id);
    });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  flipItem(index, key) {
    if (
      this.state.flippedKeys.length < 2 &&
      index !==
        this.state.flippedIndexes[this.state.flippedIndexes.length - 1] &&
      this.state.flippedIndexes.length !== this.state.items.length &&
      this.state.started &&
      this.state.memberId === this.state.activeMemberId
    ) {
      
      this.setState({...this.state,correctFlipped: [
        ...this.state.correctFlipped,
        ...this.state.flippedKeys,
      ],})
      setFlipped(
        this.props.gameRoom.id,
        [...this.state.flippedIndexes, index],
        [...this.state.flippedKeys, key],
        // [...this.state.correctFlipped,...this.state.flippedKeys]
      );
    }
  }
  gridPart() {
    if (this.state.members[this.props.gameRoom.id]) {
      let areas = 12;
      let reasons = 12 / this.state.members[this.props.gameRoom.id].length;
      let arr = [];
      for (
        let i = 1;
        i <= this.state.members[this.props.gameRoom.id].length;
        i++
      ) {
        arr.push(`${reasons}fr`);
      }

      return { gridTemplateColumns: `${arr.join(" ")}` };
    }
  }

  setColors(source, i) {
    const styles = {};

    console.log(this.state.correctFlipped, "oirhgiwejgg");
    if(this.state.correctFlipped.length%2===0){
      if(this.state.correctFlipped.filter(item=>item === source.id).length === 2){
        styles.background = "green";
      }
    }else{
      styles.background = "white";
      styles.color = 'black'
    }
    // if (
    //   this.state.flippedKeys.length === 0 &&
    //   ~this.state.flippedIndexes.indexOf(i)
    // ) {
    //   styles.background = "green";
    // } else {
    //   styles.background = "red";
    // }
    console.log(styles);
    return styles;
  }


  render() {
    const color = Math.random() * 255;
console.log(this.state);
    return (
      <div className="game-room">
        {!this.state.started && (
          <Alert color="warning">Waiting for players...</Alert>
        )}
        <div className="multi-header">
          {this.state.members[this.props.gameRoom.id] &&
            this.state.members[this.props.gameRoom.id].map(
              (memberId, key, arr) => (
                <div
                  key={memberId}
                  style={{
                    background:
                      key === 1
                        ? "#004892"
                        : key === 2
                        ? "#42BBFF"
                        : key === 3
                        ? "#42BBFF"
                        : "green",
                    margin: 5,
                  }}
                  className={
                    memberId === this.state.memberId
                      ? "player active"
                      : "player"
                  }
                >
                  <h4 style={{ color: "white" }}>Student {key + 1}</h4>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ color: "white" }}>Score: </p>
                    <div
                      style={{
                        width: 50,
                        height: 30,
                        background: "#F1F4F6",
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.state.points[memberId]}
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
        <div
          style={{ background: "#F1F4F6" }}
          className={"arena " + Difficulties[this.props.gameRoom.difficulty]}
        >
          {this.state.items &&
            this.state.items.map((source, i) => {
              return (
                <div
                  key={i}
                  style={{ ...this.setColors(source, i) }}
                  className={
                    this.state.flippedIndexes &&
                    ~this.state.flippedIndexes.indexOf(i)
                      ? "item matched"
                      : "item flipped flipped-background"
                  }
                  onClick={() => this.flipItem(i, source.id)}
                 >
                  <div className="front face" style={{lineBreak:'anywhere'}}>{source.name}</div>
                  <div className="back face" />
                </div>
              );
            })}
        </div>
        <div className="sign-out" onClick={this.props.endGame}>
          <i className="fas fa-sign-out-alt"></i>
        </div>
        <Modal
          isOpen={this.state.gameEnded}
          winner={this.state.winner}
          memberId={this.state.memberId}
        />
        <MemoryGameFooterList />
      </div>
    );
  }
}

MultiPlayer.propTypes = {
  gameRoom: PropTypes.object.isRequired,
  timeEnded: PropTypes.bool.isRequired,
  endGame: PropTypes.func.isRequired,
  increaseTime: PropTypes.func.isRequired,
};

export default MultiPlayer;
